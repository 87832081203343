const checkLabelLargerInput = (label: string) =>
  label
    ? { height: '56px', paddingTop: '18px', paddingBottom: '10px' }
    : { height: '56px' }
const setLargerInput = (value: string | null, label: string) =>
  value
    ? checkLabelLargerInput(label)
    : { height: '56px', paddingTop: '14px', paddingBottom: '14px' }

const typeAheadInputStyle = (value: string | null, label: string) => {
  return setLargerInput(value, label)
}

export default typeAheadInputStyle
