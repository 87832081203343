function easeInOutQuad(t: number, b: number, c: number, d: number) {
  // eslint-disable-next-line no-param-reassign
  t /= d / 2
  if (t < 1) {
    return (c / 2) * t * t + b
  }
  // eslint-disable-next-line no-plusplus, no-param-reassign
  t--
  return (-c / 2) * (t * (t - 2) - 1) + b
}

class ScrollAnimate {
  // eslint-disable-next-line class-methods-use-this
  scrollTo(to: number, callback = () => undefined, duration = 500) {
    function move(amount: number) {
      document.documentElement.scrollTop = amount
      document.body.scrollTop = amount
    }

    const getPosition = () =>
      document.documentElement.scrollTop || document.body.scrollTop

    const start = getPosition()
    const change = to - start
    const increment = 20
    let currentTime = 0
    const animateScroll = () => {
      const requestAnimFrame = (() => {
        return (
          window.requestAnimationFrame ||
          (cb => {
            window.setTimeout(cb, 1000 / 60)
          })
        )
      })()
      currentTime += increment
      const val = easeInOutQuad(currentTime, start, change, duration)
      move(val)
      if (currentTime < duration) {
        requestAnimFrame(animateScroll)
      } else if (callback) {
        callback()
      }
    }
    animateScroll()
  }
}

// eslint-disable-next-line import/prefer-default-export
export const scrollAnimate = new ScrollAnimate()
