/* eslint-disable react/destructuring-assignment */
import React from 'react'
import fetch from 'isomorphic-unfetch'
import debounce from 'lodash/debounce'
import CONFIG from 'isomorphic-config'
import { LOCATION_SEARCH_TYPE, TypeAheadConfigEndpoint } from '@/types'

type FetchTypeAheadProps = {
  searchValue: string | null
  searchKey: string
  onLoaded: () => void
  render: ({
    items,
    city
  }: {
    items: LOCATION_SEARCH_TYPE[]
    city?: string
  }) => JSX.Element
  typeAheadEndpoint: TypeAheadConfigEndpoint
}

type FetchTypeAheadState = {
  loading: boolean
  error: unknown
  items: LOCATION_SEARCH_TYPE[]
  city?: string
}

class FetchTypeAhead extends React.Component<
  FetchTypeAheadProps,
  FetchTypeAheadState
> {
  static initialState = { loading: false, error: null, items: [] }

  mounted = false

  fetch = debounce(() => {
    if (!this.mounted) {
      return
    }
    const { searchValue, typeAheadEndpoint } = this.props
    if (searchValue?.trim()) {
      const locationConfig = CONFIG.client.typeahead.location
      const isRCSearchEndPoint = typeAheadEndpoint === 'cars'
      const parsedURLSuffix = isRCSearchEndPoint
        ? ''
        : locationConfig.suffix[typeAheadEndpoint]
      const parseURLSuffixQS = isRCSearchEndPoint
        ? locationConfig.querystring.cars
        : ''
      const url = `${locationConfig.endpoint[typeAheadEndpoint]}${searchValue}${parsedURLSuffix}${parseURLSuffixQS}`
      fetch(url)
        .then(res => res.json())
        .then(({ searchItems = [] }) => {
          if (this.mounted && searchItems) {
            this.props.onLoaded()
            this.setState({ loading: false, items: searchItems })
          }
        })
        .catch(error => {
          if (this.mounted) {
            this.props.onLoaded()
            this.setState({ loading: false, error })
          }
        })
    }
  }, 300)

  constructor(props: FetchTypeAheadProps) {
    super(props)
    this.state = FetchTypeAhead.initialState
  }

  componentDidMount() {
    this.mounted = true
    this.prepareFetch()
    this.fetch()
  }

  componentDidUpdate(prevProps: FetchTypeAheadProps) {
    if (
      prevProps.searchValue !== this.props.searchValue ||
      prevProps.searchKey !== this.props.searchKey
    ) {
      this.prepareFetch()
      this.fetch()
    }
  }

  componentWillUnmount() {
    this.mounted = false
  }

  prepareFetch() {
    this.setState({ loading: false })
  }

  render() {
    return this.props.render(this.state)
  }
}

export default FetchTypeAhead
