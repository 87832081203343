import React, { type SyntheticEvent } from 'react'
import useSeti from '@/hooks/useSeti'
import { Box, Button, Text, Flex, Image } from 'pcln-design-system'
import { ChevronRight } from 'pcln-icons'
import styled from 'styled-components'
import analytics from '@/shared-utils/analytics'

const StyledButton = styled(Button)`
  position: relative;
  &:hover {
    background: transparent;
  }
`

const StickyBox = styled(Box)`
  position: sticky;
  bottom: 0;
`

const pennyLogo =
  'https://s1.pclncdn.com/design-assets/pcln-bot/logos/pcln_bot_opener_logo_no_border_v1.1.svg'

function TypeaheadPenny() {
  const isPennyTypeaheadActive = useSeti('HP_HOTEL_TYPEAHEAD_PENNY', false)
  const pennyText =
    isPennyTypeaheadActive === 'VARIANT'
      ? 'Find the best hotel with Penny'
      : 'Need a hotel? Let Penny help.'

  return (
    <StickyBox bg="promoPrimary.light" boxShadow="lg" mt={[3, null, 0]}>
      <StyledButton
        variation="plain"
        width={1}
        p={2}
        borderRadius="none"
        id="dropdown-penny-button"
        // onClick does not work here on Safari, affecting accessibility
        // Need refactor fixing onClick in Safari (affects other instances too)
        onMouseDown={(e: SyntheticEvent) => {
          e.stopPropagation()
          analytics.fireGA4Event({
            event: 'internal_link',
            attributes: {
              link_name: 'penny',
              link_item: 'type_ahead',
              page_name: 'homepage',
              product_name: 'hotel'
            }
          })
          window.location.href =
            '/?openPenny=y&askPrompt=I%20need%20help%20finding%20a%20hotel'
        }}
      >
        <Flex alignItems="center" justifyContent="space-between" width={1}>
          <Flex alignItems="center">
            <Box mx={[0, null, 1]} px={[3, null, 0]}>
              <Image
                src={pennyLogo}
                width={['24px', null, '32px']}
                height={['24px', null, '32px']}
              />
            </Box>
            <Text pl={[0, null, 2]} color="text.base" textStyle="paragraph">
              {pennyText}
            </Text>
          </Flex>
          <ChevronRight />
        </Flex>
      </StyledButton>
    </StickyBox>
  )
}

export default TypeaheadPenny
