import { useQuery } from '@tanstack/react-query'
import config from 'isomorphic-config'
import pclnRequest from '@pcln/request'
import { AxiosError, AxiosResponse } from 'axios'
import { GraphResponse } from '@/types'
import {
  RecentTripSearches,
  SearchProductData,
  SearchProductDataFiltered
} from '@/components/TripActivity/types'
import useSeti from '@/hooks/useSeti'
import analytics from '@/shared-utils/analytics'
import getCookieByName from '@/shared-utils/get-cookie-by-name'
import hasGraphErrors from '@/shared-utils/graph-utils'
import fetchRecentTripSearches from '@/shared-utils/fetchRecentTripSearches'

type SearchResults = {
  searchProductData: ReadonlyArray<SearchProductData> | null
}

type SearchError = {
  searchError: string | null
}

type SearchResponse = {
  customerSearches: Readonly<SearchResults | SearchError>
}

type ApiMetadata = {
  appName: string
  appVersion: string
}

type SearchProduct = 'STAY' | 'FLY' | 'DRIVE'

export type SearchProductList = readonly [
  SearchProduct,
  SearchProduct?,
  SearchProduct?
]

function isFilteredDataForStartDate(value: SearchProductData) {
  return (
    (value.productType === 'FLY' &&
      value.flyOriginDestinationData[0].startDate !== null) ||
    (value.productType === 'DRIVE' && value.driveStartDate !== null) ||
    (value.productType === 'STAY' &&
      value.stayDestinationData[0].startDate !== null)
  )
}

const hotelQuery = `
      ... on StaySearchData {
        productType
        stayRoomCount
        stayDestinationData {
          destinationCityData {
            cityID
            cityName
          }
          startDate
          endDate
        }
      }
    `
const flightQuery = `
      ... on FlySearchData {
        productType
        flyCabinClass
        flyTripType
        flyTravelersCount {
          adultCount
          childCount
          infantCount
        }
        flyOriginDestinationData {
          originDestinationData {
            originCityData {
              cityName
            }
            destinationCityData {
              cityName
            }
            originAirportData {
              airportCode
            }
            destinationAirportData {
              airportCode
            }
          }
          startDate
        }
      }
    `

const carQuery = `
      ... on DriveSearchData {
        productType
        driveTripType
        driveStartDate
        driveEndDate
        drivePickupTime
        driveDropOffTime
        driveOriginDestinationData {
          originCityData {
            cityName
          }
          originAirportData {
            airportCode
            airportName
          }
          destinationAirportData {
            airportCode
            airportName
          }
          destinationCityData {
            cityName
          }
        }
      }
    `

async function fetchRecentSearches(
  cguid: string,
  apiMetadata: ApiMetadata,
  searchProducts: SearchProductList
) {
  const { url, timeout } = config.client['pcln-graph']
  const options = {
    url: `${url}?gqlOp=customerSearches`,
    timeout,
    method: 'post',
    headers: {
      authToken: getCookieByName('dmc'),
      'apollographql-client-name': apiMetadata.appName,
      'apollographql-client-version': apiMetadata.appVersion
    },
    data: {
      query: `
          query CustomerSearches($cguid: ID!, $searchProducts: [SearchProductEnum]!) {
            customerSearches(cguid: $cguid, customerSearchInput: {
              rankType: RANKING_LAST_SEARCH
              searchProductType: $searchProducts
            }) {
              ... on SearchResults {
                searchProductData {
                  ${searchProducts.includes('STAY') ? hotelQuery : ''}
                  ${searchProducts.includes('FLY') ? flightQuery : ''}
                  ${searchProducts.includes('DRIVE') ? carQuery : ''}
                }
              }
              ... on SearchError {
                searchError
              }
            }
          }
          `,
      variables: {
        cguid,
        searchProducts
      }
    }
  }

  try {
    const response: AxiosResponse<GraphResponse<SearchResponse>> =
      await pclnRequest(options)

    if (hasGraphErrors(response.data)) {
      const { errors } = response.data
      analytics.logError({
        message: 'Could not complete Recent Search Card request',
        cguid,
        errors,
        products: searchProducts.join(', ')
      })
      return []
    }

    const { customerSearches } = response.data.data

    if ('searchError' in customerSearches) {
      if (
        customerSearches.searchError !==
        'No Search Data found for the requested SearchProduct(s).'
      ) {
        analytics.logError({
          message: 'Could not complete Recent Search Card request',
          error:
            customerSearches.searchError ??
            'searchError did not return error message',
          cguid,
          products: searchProducts.join(', ')
        })
      }
      return []
    }

    const filteredData =
      customerSearches.searchProductData?.filter(isFilteredDataForStartDate) ??
      []

    return filteredData as ReadonlyArray<SearchProductDataFiltered>
  } catch (e) {
    const error = e as AxiosError
    analytics.logError({
      message: 'Error getting Recent Search Card request data',
      error,
      products: searchProducts.join(', '),
      cguid
    })
    return []
  }
}

export default function useRecentSearchCard(
  cguid: string,
  apiMetadata: ApiMetadata,
  searchProducts: SearchProductList,
  signedIn: boolean
) {
  const isNewRecentSearchQuery = useSeti('HP_RT_SEARCHES') === 'VARIANT'
  const { data = [] } = useQuery<
    ReadonlyArray<SearchProductDataFiltered | RecentTripSearches>
  >({
    queryKey: [
      searchProducts,
      cguid,
      apiMetadata,
      isNewRecentSearchQuery,
      signedIn
    ],
    queryFn: () =>
      isNewRecentSearchQuery
        ? fetchRecentTripSearches(cguid, apiMetadata, searchProducts, signedIn)
        : fetchRecentSearches(cguid, apiMetadata, searchProducts),
    staleTime: Infinity
  })

  return data
}
