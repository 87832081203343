import React from 'react'
import styled from 'styled-components'
import {
  Button,
  Box,
  Label,
  Tooltip,
  Flex,
  Text,
  getPaletteColor,
  type ButtonProps
} from 'pcln-design-system'
import { Warning as WarningIcon, Calendar as CalendarIcon } from 'pcln-icons'

interface DateFieldProps extends Omit<ButtonProps, 'onFocus'> {
  name: string
  label: string
  value: string
  error?: string
  hideTooltip?: boolean
  index?: number
  onFocus?: () => void
}

function IconRight() {
  return <WarningIcon color="error" size={20} />
}

function IconLeft() {
  return <CalendarIcon color="primary" mr={2} />
}

const StyledLabel = styled(Label)`
  height: 18px;
`

const StyledButton = styled(Button)`
  &[role='combobox'] {
    position: relative;
    height: 56px;
    padding-left: 8px;
    padding-right: 8px;
    &[aria-invalid='true'] {
      border-color: ${getPaletteColor('error.base')};
    }
    &[data-value='true'] {
      padding-top: 6px;
    }
  }
`

const DateField = React.forwardRef<HTMLButtonElement, DateFieldProps>(
  ({ name, label, value, error = '', hideTooltip = false, ...props }, ref) => {
    const showError = !hideTooltip && error !== ''
    const labelId = `id-${new Date().getTime()}`
    const errorMessageId = `dp-error-id-${new Date().getTime()}`
    return (
      <Box>
        <StyledButton
          {...props}
          // Fix for Safari: Safari does not focus the button when clicked. Use tabIndex to force the focus behavior on click
          // https://developer.mozilla.org/en-US/docs/Web/HTML/Element/button#clicking_and_focus
          // https://stackoverflow.com/questions/42758815/safari-focus-event-doesnt-work-on-button-element
          // https://bugs.webkit.org/show_bug.cgi?id=22261
          // Alternative solution works in Safari 17 and above
          // tabIndex={0}
          onClick={() => {
            props.onFocus?.()
          }}
          borderRadius="lg"
          variation="input"
          type="button"
          name={name}
          role="combobox"
          width={1}
          IconLeft={IconLeft}
          IconRight={error ? IconRight : undefined}
          flexProps={{ justifyContent: 'left', height: '100%' }}
          aria-labelledby={labelId}
          aria-invalid={error ? 'true' : 'false'}
          aria-errormessage={error ? errorMessageId : undefined}
          data-value={value ? 'true' : 'false'}
          ref={ref}
        >
          {value ? (
            <Flex flexDirection="column" width={1} height="100%">
              <StyledLabel id={labelId} as="div">
                {label}
              </StyledLabel>
              <Text width={1} fontSize={[2, null, null, 1]}>
                {value}
              </Text>
            </Flex>
          ) : (
            <Text
              id={labelId}
              width={1}
              color="text.light"
              fontSize={[2, null, null, 1]}
            >
              {label}
            </Text>
          )}
        </StyledButton>
        {showError && (
          <Tooltip
            id={errorMessageId}
            bottom
            right
            color="error"
            zIndex={2}
            role="alert"
          >
            {error}
          </Tooltip>
        )}
      </Box>
    )
  }
)

export default DateField
