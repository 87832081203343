import React from 'react'
import styled from 'styled-components'
import { Button, theme } from 'pcln-design-system'

const StyledButton = styled(Button)<{
  animatedBoxShadow: string
  scale: number
}>`
  height: 56px;
  &:hover {
    transform: scale(${props => props.scale});
    box-shadow: ${props => props.animatedBoxShadow};
    transition: 0.2s ease-in-out;
  }
`

type SearchFormButtonProps = {
  buttonText: string
  handleClick?: () => void
  [key: string]: unknown
}
export default function SearchFormButton({
  buttonText,
  handleClick = () => {
    // empty default function
  },
  ...buttonProps
}: SearchFormButtonProps) {
  const buttonMicroAnimationProps = {
    // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
    scale: theme.scale['scale-100'] + theme.scale['scale-2'],
    animatedBoxShadow: theme.shadows.xl
  }

  return (
    <StyledButton
      width={1}
      px={1}
      py={0}
      size="large"
      onClick={handleClick}
      type="submit"
      borderRadius="lg"
      {...buttonProps}
      {...buttonMicroAnimationProps}
    >
      {buttonText}
    </StyledButton>
  )
}
