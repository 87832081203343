import React from 'react'
import { GetItemPropsOptions } from 'downshift'
import { Text } from 'pcln-design-system'
import { LOCATION_SEARCH_TYPE } from '@/types'
import SearchItem from './SearchItem'

type RenderRecentSearchesType = {
  getItemProps: (
    options: GetItemPropsOptions<LOCATION_SEARCH_TYPE>
  ) => Record<string, unknown>
  highlightedIndex: number | null
  recentSearches: LOCATION_SEARCH_TYPE[]
  recentOrRecommendationTitle: string
}

function RenderRecentSearches({
  getItemProps,
  highlightedIndex,
  recentSearches,
  recentOrRecommendationTitle
}: RenderRecentSearchesType) {
  return (
    <>
      <Text fontSize={0} p={2} fontWeight="bold">
        {recentOrRecommendationTitle}
      </Text>
      {recentSearches.map((item, index) => {
        return (
          <SearchItem
            key={item.id}
            isItemSelected={index === highlightedIndex}
            index={index}
            item={{ ...item, skipFeedbackCall: true }}
            getItemProps={getItemProps}
          />
        )
      })}
    </>
  )
}

export default RenderRecentSearches
