import { scrollAnimate } from './scroll-animate'

const isDeviceOrientationPortrait = () => {
  if (window.orientation === 0 || window.orientation === 180) {
    return true
  }
  return false
}

const isWebKitBrowser = () => {
  const ua = window.navigator.userAgent
  const iOS = !!ua.match(/iPhone/i)
  const webkit = !!ua.match(/WebKit/i)
  return iOS && webkit
}

export function scrollToTop() {
  if (isWebKitBrowser() && isDeviceOrientationPortrait()) {
    scrollAnimate.scrollTo(10)
  }
}

export function restoreScrollPosition(scrollToYCoordinates: number) {
  if (
    isWebKitBrowser() &&
    isDeviceOrientationPortrait() &&
    scrollToYCoordinates
  ) {
    scrollAnimate.scrollTo(scrollToYCoordinates)
  }
}
