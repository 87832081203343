import { Card, type CardProps } from 'pcln-design-system'
import styled from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

const DropdownCard = styled(Card)<
  Omit<CardProps, 'minWidth'> & { minWidth: string; visible: boolean }
>`
  max-height: 340px;
  overflow-y: auto;
  border-color: transparent;
  &:focus {
    outline: none;
  }
  ${({ minWidth }: { minWidth: string }) =>
    minWidth &&
    `
    min-width: ${minWidth};
  `}
  display: ${props => (props.visible ? 'block' : 'none')};
  position: absolute;
  z-index: 2;
  &::-webkit-scrollbar {
    width: 0px;
    ${themeGet('mediaQueries.md')} {
      width: 10px;
    }
  }
  &::-webkit-scrollbar-track {
    margin-block: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 16px;
    background-clip: content-box;
  }
`

export default DropdownCard
