import React from 'react'
import { Flex, type FlexProps, getPaletteColor } from 'pcln-design-system'
import styled from 'styled-components'

type FlexContainerProps = FlexProps & {
  isHighlighted?: boolean
}

function FlexContainer({ className, children, ...rest }: FlexContainerProps) {
  return (
    <Flex
      className={className}
      borderRadius="lg"
      m={0}
      role="option"
      width={1}
      {...rest}
    >
      {children}
    </Flex>
  )
}

const DropdownItem = styled(FlexContainer)`
  background-color: ${props =>
    props.isHighlighted ? getPaletteColor('background.base') : null};
  color: ${props =>
    props.isHighlighted ? getPaletteColor('text.base') : null};
  cursor: pointer;
  align-items: center;

  &:hover {
    background-color: ${props =>
      props.isHighlighted ? getPaletteColor('background.base') : null};
    color: ${props =>
      props.isHighlighted ? getPaletteColor('text.base') : null};
  }

  &:focus {
    background-color: ${getPaletteColor('background.base')};
    color: ${getPaletteColor('text.base')};
  }
`

export default DropdownItem
