import React, { SyntheticEvent, useEffect, useRef } from 'react'
import styled, { withTheme } from 'styled-components'
import {
  type BorderRadiusProps,
  type LeftProps,
  type PaddingProps,
  type RightProps,
  style
} from 'styled-system'
import { themeGet } from '@styled-system/theme-get'
import { Box, Flex, Hide, Button, type BoxProps } from 'pcln-design-system'
import useBootstrapData from '@/hooks/useBootstrapData'
import { ArrowLeft } from 'pcln-icons'
import {
  restoreScrollPosition,
  scrollToTop
} from '../utils/popover-scroll-helper'

interface RootProps extends PopoverProps {
  zIndex?: number
  top?: number | string
  color?: string
}

const Root = styled(Box)`
  right: 0;
  bottom: 0;
  left: 0;
  ${(props: RootProps) =>
    props.open
      ? {
          position: 'fixed',
          perspective: '600px',
          zIndex: props.zIndex || 0,
          top: props.top || 0,
          display: 'flex',
          flexDirection: 'column',
          [themeGet('mediaQueries.1')(props)]: {
            position: 'relative',
            top: 'auto',
            display: 'block'
          }
        }
      : null};
`

interface PopoverProps
  extends PopoverContainerProps,
    BorderRadiusProps,
    PaddingProps {
  color?: string
  title?: string
  width?: number
  zIndex?: number
}

export function Popover({
  open = false,
  onDismiss,
  domSelectorForVisuallyContainedElement = '',
  ...props
}: PopoverProps) {
  const root = useRef<Element>()
  const mounted = useRef(false) // Used to implement componentDidUpdate logic in hooks
  useEffect(() => {
    const handleDismiss = (event: Event) => {
      if (
        !open ||
        !root.current ||
        (event.target instanceof Element && root.current.contains(event.target))
      )
        return
      if (
        domSelectorForVisuallyContainedElement &&
        typeof (event.target as HTMLElement).closest === 'function' &&
        (event.target as HTMLElement).closest(
          domSelectorForVisuallyContainedElement
        )
      ) {
        return
      }
      if (onDismiss) {
        onDismiss()
      }
    }
    document.addEventListener('click', handleDismiss)
    return () => {
      document.removeEventListener('click', handleDismiss)
    }
  }, [open, domSelectorForVisuallyContainedElement, onDismiss])

  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true
    }
  }, [])

  useEffect(() => {
    // The useEffect hook here mimics the componentDidUpdate life-cycle hook
    // The scrollY position stored can be used in the return function and passed to restoreScrollPosition
    // In this way we can avoid storing the scrollY in any state in the Popover
    const scrollYPosition = window.scrollY
    if (mounted.current && open === true) {
      scrollToTop()
    }

    return () => {
      if (!mounted.current) return
      if (open === true) {
        restoreScrollPosition(scrollYPosition)
      }
    }
  }, [open])

  return (
    <Root
      ref={(ref: HTMLDivElement) => {
        root.current = ref
      }}
      open={open}
      {...props}
    />
  )
}

const left = style({
  prop: 'left',
  cssProperty: 'left'
})

const right = style({
  prop: 'right',
  cssProperty: 'right'
})

/*
On mounting, the calendar needs to scroll to the selected dates
This will be addressed in v2 date picker (desktop), but mobile (unconfirmed)
*/
const calendarHeight = (props: { openState: boolean; isCalendar?: boolean }) =>
  props.isCalendar
    ? {
        height: '100%'
      }
    : null

Popover.Body = styled(Box)<
  BoxProps &
    LeftProps &
    RightProps & { openState: boolean; isCalendar?: boolean }
>`
  transform-origin: top center;
  &.fade-enter {
    opacity: 0;
    transform: rotateX(-45deg);
    transition: all 200ms linear;
  }
  &.fade-enter-active {
    opacity: 1;
    transform: rotateX(0);
  }
  &.fade-exit {
    opacity: 1;
    transform: rotateX(0);
  }
  &.fade-exit-active {
    opacity: 0;
    transform: rotateX(-45deg);
    transition: all 200ms linear;
  }

  flex: 1 1 auto;
  height: ${props => (props.openState ? 'calc(96vh - 64px)' : 0)};
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  ${left} ${right} ${themeGet('mediaQueries.1')} {
    position: absolute;
    overflow: visible;
    height: auto;
  }

  /* hack for calendar scroll issue */
  & > div {
    ${calendarHeight};
  }
`

interface PopoverContainerProps {
  open?: boolean
  onDismiss?: () => void
  position?: string
  children?: React.ReactNode | React.ReactNode[]
  popoverButtonProps?: Record<string, unknown>
  ['data-calendar']?: boolean
  theme: Record<string, unknown>
  domSelectorForVisuallyContainedElement?: string
  isMobile?: boolean
  disabled?: boolean
  top?: number | string
}

// Simpler API
function PopoverRootContainer({
  open = false,
  'data-calendar': isCalendar = false,
  onDismiss,
  children,
  position = 'left',
  popoverButtonProps = {},
  ...props
}: PopoverContainerProps) {
  const { isMobile = false } = useBootstrapData()
  const [top, body] = React.Children.toArray(children)
  return (
    <Popover
      {...props}
      open={open}
      onDismiss={onDismiss}
      width={1}
      {...(open ? { zIndex: 2 } : {})}
    >
      <Box
        py={open ? [3, null, 0] : 0}
        mt={open && isMobile ? 2 : 0}
        pb={open ? [2, null, 0] : 0}
        color="background.lightest"
        borderRadius={['2xl', null, 'lg']}
        rounded={isMobile ? 'top' : undefined}
      >
        <Flex alignItems="center" px={open ? [3, null, 0] : 0}>
          {open && (
            <Hide md lg xl xxl pr={2} pb={[3, null, 0]}>
              <Button
                size="large"
                data-testid="popover-back-button"
                onClick={(event: SyntheticEvent) => {
                  event.preventDefault()
                  if (onDismiss) {
                    onDismiss()
                  }
                }}
                {...popoverButtonProps}
              >
                <ArrowLeft />
              </Button>
            </Hide>
          )}
          <Box width={1} pb={[3, null, 0]}>
            {top}
          </Box>
        </Flex>
        {open && (
          <Popover.Body
            color={isCalendar ? undefined : 'background.lightest'}
            openState={open}
            isCalendar={isCalendar}
            right={position === 'right' ? 0 : null}
            left={position === 'left' ? 0 : null}
            mt={[0, null, isCalendar ? 2 : 0]}
            mr={[0, 0, -3, 0]}
            width={[null, null, isCalendar ? null : 1]}
          >
            {body}
          </Popover.Body>
        )}
      </Box>
    </Popover>
  )
}

export const PopoverContainer = withTheme(PopoverRootContainer)
