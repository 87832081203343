import { useCallback, useRef } from 'react'

type FieldElement = HTMLInputElement | HTMLSelectElement | HTMLButtonElement

export default function useTabGroup() {
  const tabElements = useRef<Set<FieldElement>>(new Set())

  function registerTabElement(ref: FieldElement | null): void {
    if (ref) {
      tabElements.current.add(ref)
    }
  }
  const focusNextElement = useCallback(() => {
    const elementList = Array.from(tabElements.current)

    if (!document) {
      return
    }

    const { activeElement } = document
    if (
      activeElement &&
      (activeElement instanceof HTMLInputElement ||
        activeElement instanceof HTMLSelectElement)
    ) {
      const selectedItemIndex = elementList.indexOf(activeElement)

      if (
        selectedItemIndex === -1 ||
        selectedItemIndex + 1 >= elementList.length
      ) {
        return setTimeout(() => elementList[0].focus(), 0)
      }
      return setTimeout(() => elementList[selectedItemIndex + 1].focus(), 0)
    }
  }, [])

  return {
    registerTabElement,
    focusNextElement
  }
}
