export const STAY_FLY = 'STAY:FLY'
export const STAY_FLY_DRIVE = 'STAY:FLY:DRIVE'
export const FLY_DRIVE = 'FLY:DRIVE'
export const STAY_DRIVE = 'STAY:DRIVE'
const HOTEL = 'STAY'
export const FLIGHT = 'FLY'
export const CAR = 'DRIVE'

export const TripTypeInfos = {
  [STAY_FLY]: {
    id: 'tripTypeAH',
    tripType: 'STAY:FLY',
    typeLabel: 'Hotel + Flight'
  },
  [STAY_FLY_DRIVE]: {
    id: 'tripTypeAHC',
    tripType: 'STAY:FLY:DRIVE',
    typeLabel: 'Hotel + Flight + Car'
  },
  [FLY_DRIVE]: {
    id: 'tripTypeAC',
    tripType: 'FLY:DRIVE',
    typeLabel: 'Flight + Car'
  },
  [STAY_DRIVE]: {
    id: 'tripTypeHC',
    tripType: 'STAY:DRIVE',
    typeLabel: 'Hotel + Car'
  }
} as const

export const isHotelPlusCar = (tripType: string) => tripType === STAY_DRIVE
export const hasHotel = (tripType: string) => tripType.includes(HOTEL)
export const hasFlight = (tripType: string) => tripType.includes(FLIGHT)
