import { useEffect, useState } from 'react'
import { LOCATION_SEARCH_TYPE, CityId } from '@/types'
import { RecentHotelSearches } from '@/components/TripActivity/types'
import useRecentSearchCard from './useRecentSearchCard'
import useRecommendations from './useRecommendations'
import useSeti from './useSeti'

export default function useRecentSearchesFetched(
  cguid: string,
  appName: string,
  appVersion: string,
  signedIn: boolean
) {
  const [recentLocations, setRecentLocations] = useState<
    LOCATION_SEARCH_TYPE[]
  >([])
  const [cityIdList, setCityIdList] = useState<CityId[]>([])
  const data = useRecentSearchCard(
    cguid,
    { appName, appVersion },
    ['STAY'],
    signedIn
  )
  const isNewRecentSearchQuery = useSeti('HP_RT_SEARCHES', false) === 'VARIANT'

  // Clean up HP_RT_SEARCHES: Remove the old Query response property i.e. stayDestinationData when the new Query wins.
  useEffect(() => {
    data.forEach(item => {
      if (!isNewRecentSearchQuery && 'stayDestinationData' in item) {
        const { cityID } = item.stayDestinationData[0].destinationCityData
        setCityIdList(prevCityId => [...prevCityId, { label: cityID }])
      }

      if (isNewRecentSearchQuery && item.productType === 'STAY') {
        const { cityId } = item as RecentHotelSearches
        setCityIdList(prevCityId => [...prevCityId, { label: cityId }])
      }
    })
  }, [data, isNewRecentSearchQuery])

  const cityInfoList = useRecommendations(cityIdList)
  useEffect(() => {
    cityInfoList.forEach(item => {
      setRecentLocations(oldCityInfoList => [
        ...oldCityInfoList,
        {
          ...item,
          country: item.countryName,
          entered: '',
          highlightedName: null,
          id: item.cityID.toString(),
          type: 'CITY',
          cityID: item.cityID.toString()
        }
      ])
    })
  }, [cityInfoList])
  return recentLocations
}
