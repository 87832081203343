import React, { useState, useEffect } from 'react'
import { type FieldErrors, UseFormSetValue } from 'react-hook-form'
import { Box, Label, Input, FormField, Tooltip } from 'pcln-design-system'
import { Search as SearchIcon, Warning as WarningIcon } from 'pcln-icons'
import Downshift, {
  ControllerStateAndHelpers,
  DownshiftState,
  StateChangeOptions
} from 'downshift'
import { useMachine } from '@xstate/react'
import fetch from 'isomorphic-unfetch'
import CONFIG from 'isomorphic-config'
import { PopoverContainer } from '@/components/Popover'
import { getErrorFromKey } from '@/shared-utils/error-helpers'
import useRecentSearchesLocalStorage from '@/hooks/useRecentSearchesLocalStorage'
import {
  AIR_SEARCH_DATA,
  CAR_SEARCH_DATA,
  HOTEL_SEARCH_DATA,
  PACKAGE_SEARCH_DATA
} from '@/shared-utils/local-forage-helper'
import useRecommendations from '@/hooks/useRecommendations'
import { LOCATION_SEARCH_TYPE, TypeAheadConfigEndpoint, CityId } from '@/types'
import useBootstrapData from '@/hooks/useBootstrapData'
import useRecentSearchesFetched from '@/hooks/useRecentSearchesFetched'
import useTypeAheadForDisplayName from './useTypeAheadForDisplayName'
import geoMachine from '../../machines/currentLocationMachine'
import Dropdown from './Dropdown'
import typeAheadInputStyle from './StyledInput'
import fireTypeaheadClickEvent from './ga4'

// eslint-disable-next-line @typescript-eslint/no-empty-function
const noop = () => {}

const mapTypeToCode = {
  AIRPORT: 'A',
  CITY: 'C',
  HOTEL: 'H',
  POI: 'P',
  GEO: 'GEO',
  LATLON: 'LATLON',
  PARTNER_LOC: 'PARTNER_LOC',
  GDS_CITY: 'GDS_CITY',
  ADDRESS: 'ADDRESS',
  HOTEL_CITY: 'HOTEL_CITY'
}

const recentSearchesKey = {
  cars: CAR_SEARCH_DATA,
  hotels: HOTEL_SEARCH_DATA,
  flights: AIR_SEARCH_DATA,
  packagesOrigin: PACKAGE_SEARCH_DATA,
  packagesDestination: PACKAGE_SEARCH_DATA,
  flightsNearbyAirport: AIR_SEARCH_DATA
} as const

type TypeAheadProps = {
  searchProduct: TypeAheadConfigEndpoint
  label: string
  placeholder: string
  searchKey: string
  defaultSelectedItem: LOCATION_SEARCH_TYPE | null
  errors: FieldErrors
  disableShadowState: () => void
  skipCustomDisplayName?: boolean
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setValue?: UseFormSetValue<any>
  onItemSelect?: (item: LOCATION_SEARCH_TYPE) => void
  focusNextElement?: () => unknown
  showCurrentLocation?: boolean
  isTwoLineDisplay?: boolean
  enableRecentSearchExperiment?: boolean
  enableFetchedRecentSearches?: boolean
  recommendedCityIds?: ReadonlyArray<CityId>
  shouldDisplayPenny?: boolean
}

const TypeAhead = React.forwardRef<HTMLInputElement, TypeAheadProps>(
  (
    {
      label,
      placeholder,
      searchProduct,
      searchKey,
      setValue = noop,
      defaultSelectedItem,
      errors,
      disableShadowState,
      skipCustomDisplayName = false,
      focusNextElement = noop,
      onItemSelect = noop,
      showCurrentLocation = false,
      isTwoLineDisplay,
      enableFetchedRecentSearches = false,
      recommendedCityIds,
      shouldDisplayPenny
    },
    ref
  ) => {
    const {
      isMobile,
      webstats: { clientGUID },
      appName,
      appVersion,
      signInStatus: { signedIn }
    } = useBootstrapData()

    const handleInputKeyDown = ({
      event,
      isOpen,
      selectHighlightedItem
    }: {
      isOpen: boolean
      event: React.KeyboardEvent<HTMLInputElement>
      selectHighlightedItem: () => void
    }) => {
      if (isOpen && ['Tab'].includes(event.key)) {
        event.preventDefault()
        selectHighlightedItem()
        focusNextElement()
      }
    }

    const typeAheadEndpoint = searchProduct
    const currentRecentSearchesKey = recentSearchesKey[typeAheadEndpoint]
    const [recentStartLocations, recentEndLocations] =
      useRecentSearchesLocalStorage(currentRecentSearchesKey)

    const recentSearchesLocalStorage = searchKey.includes('startLocation')
      ? recentStartLocations
      : recentEndLocations
    const recentSearchesFetched = useRecentSearchesFetched(
      clientGUID,
      appName,
      appVersion,
      signedIn
    )
    const recentSearches = enableFetchedRecentSearches
      ? recentSearchesFetched
      : recentSearchesLocalStorage

    const handleTypeAheadFeedback = ({
      entered,
      id,
      itemName,
      displayName,
      type,
      selectedPosition,
      skipFeedbackCall
    }: LOCATION_SEARCH_TYPE & {
      selectedPosition: number | null
      skipFeedbackCall?: boolean
    }) => {
      if (skipFeedbackCall) return

      // swagger for feedback call api http://ny-htlindex-001:8080/typeahead/index/swagger-ui.html#/feedback-controller/typeaheadFeedbackWithSelectedPositionUsingGET
      const url = `${
        CONFIG.client.typeahead.feedback[typeAheadEndpoint]
      }/${id}/${encodeURIComponent(entered ?? 'null')}/${encodeURIComponent(
        itemName || displayName || ''
      )}/${selectedPosition ?? ''}`
      const queryParams = `language=en&locale=en-US&placeType=${mapTypeToCode[type]}`
      void fetch(`${url}?${queryParams}`)
    }

    const [isPopoverOpen, setIsPopoverOpen] = useState(false)

    type HandleSelectType = (
      fn: () => void
    ) => (
      selectedItem: LOCATION_SEARCH_TYPE | null,
      stateAndHelpers: ControllerStateAndHelpers<LOCATION_SEARCH_TYPE>
    ) => void
    const stateReducer = (
      state: DownshiftState<LOCATION_SEARCH_TYPE>,
      changes: StateChangeOptions<LOCATION_SEARCH_TYPE>
    ) => {
      switch (changes.type) {
        case Downshift.stateChangeTypes.keyDownEnter:
        case Downshift.stateChangeTypes.clickItem:
        case Downshift.stateChangeTypes.mouseUp: // case for item selection on input blur
        case undefined: // handle case for item selection when you use TAB key. This seems to be a bug in Downshift. As per the type system, changes.type cannot be null
        case Downshift.stateChangeTypes.unknown: {
          const { highlightedIndex, inputValue } = state
          if (
            state.isOpen &&
            Object.prototype.hasOwnProperty.call(changes, 'isOpen')
          ) {
            return {
              ...changes,
              // persist value of highlightedIndex after item selection
              // Normally highlightedIndex changes to defaultHighlightedIndex after selection
              highlightedIndex,
              inputValue
            }
          }
          return changes
        }
        default:
          return changes
      }
    }

    const canDisplayRecommendations =
      recommendedCityIds && recommendedCityIds.length > 0
    const handleSelect: HandleSelectType =
      disableShadowEffect =>
      (selectedItem, { reset, highlightedIndex }) => {
        setValue(searchKey, selectedItem, { shouldDirty: true })
        reset()
        const isRecentSearchesVisible = recentSearches.length > 0
        let eventType
        if (isRecentSearchesVisible) {
          eventType = 'recent_searches'
        } else if (canDisplayRecommendations) {
          eventType = 'recommendations'
        } else {
          eventType = 'typein'
        }
        if (selectedItem) {
          onItemSelect(selectedItem)
          fireTypeaheadClickEvent(typeAheadEndpoint, eventType)
          handleTypeAheadFeedback({
            ...selectedItem,
            selectedPosition: highlightedIndex
          })
          disableShadowEffect()
          setIsPopoverOpen(false)
        }
      }

    const itemDisplayNameTransform = useTypeAheadForDisplayName(
      searchProduct,
      skipCustomDisplayName
    )
    const itemToString = (i: LOCATION_SEARCH_TYPE | null) => {
      return itemDisplayNameTransform?.(i) ?? i?.itemName ?? ''
    }

    const errorMessage = getErrorFromKey(searchKey)(errors)

    const [current, send, service] = useMachine(geoMachine)

    const currentLocationProps = {
      current,
      showCurrentLocation,
      handleRequestCurrentLocationClick: () => {
        send('REQUEST')
      }
    }
    useEffect(() => {
      const subscription = service.subscribe(state => {
        if (state.matches('resolved')) {
          disableShadowState()
          setValue(searchKey, state.context.cityData, { shouldDirty: true })
          onItemSelect(state.context.cityData)
          setIsPopoverOpen(false)
        }
      })

      return () => subscription.unsubscribe()
    }, [service, setValue, searchKey, onItemSelect, disableShadowState])

    const [recentOrRecommendationData, setRecentOrRecommendationData] =
      useState<LOCATION_SEARCH_TYPE[]>([])
    const [recentOrRecommendationTitle, setRecentOrRecommendationTitle] =
      useState('')

    const recommendedSearches = useRecommendations(recommendedCityIds)

    useEffect(() => {
      if (canDisplayRecommendations) {
        setRecentOrRecommendationData(
          recommendedSearches as unknown as LOCATION_SEARCH_TYPE[]
        )
        setRecentOrRecommendationTitle('Recommended Searches')
      } else {
        setRecentOrRecommendationData(recentSearches.slice(0, 3))
        setRecentOrRecommendationTitle('Recent Searches')
      }
    }, [
      recommendedCityIds,
      recommendedSearches,
      recentSearches,
      canDisplayRecommendations,
      enableFetchedRecentSearches
    ])

    const errorMessageId = `typeahead-error-${new Date().getTime()}`
    const errorProps = {
      id: errorMessageId,
      style: { zIndex: 1 },
      bottom: true,
      right: true,
      color: 'error',
      role: 'alert'
    }

    return (
      <Downshift
        stateReducer={stateReducer}
        selectedItem={defaultSelectedItem}
        defaultHighlightedIndex={0}
        onSelect={handleSelect(disableShadowState)}
        itemToString={itemToString}
        id={`${searchKey}-typeahead-downshift-container`}
      >
        {({
          getLabelProps,
          getInputProps,
          getRootProps,
          openMenu,
          clearSelection,
          selectHighlightedItem,
          isOpen,
          inputValue,
          highlightedIndex,
          ...restProps
        }) => (
          <Box
            width={1}
            style={{ position: 'relative' }}
            {...getRootProps({
              refKey: 'ref'
            })}
            aria-controls="typeahead-dropdown"
          >
            <PopoverContainer
              open={isPopoverOpen}
              top={8}
              onDismiss={() => setIsPopoverOpen(false)}
              isMobile={isMobile}
            >
              <Box>
                <FormField>
                  <Label
                    pb={0}
                    autoHide={label === placeholder}
                    width="auto"
                    {...getLabelProps()}
                  >
                    {label}
                  </Label>
                  <SearchIcon color="primary" size={24} />
                  <Input
                    color={errorMessage ? 'red' : undefined}
                    ref={ref}
                    borderRadius="lg"
                    id={`${searchKey}-typeahead-input`}
                    data-testid={`${searchKey}-typeahead-input`}
                    {...getInputProps({
                      // exclude size explicitly
                      // Reason: size is an attribute on <input> HTML element and size is also a prop on <Input> DS component
                      // There is a type mismatch between the two size props
                      size: undefined,
                      'aria-invalid': errorMessage ? 'true' : 'false',
                      'aria-errormessage': errorMessage
                        ? errorMessageId
                        : undefined,
                      placeholder,
                      name: searchKey,
                      style: typeAheadInputStyle(inputValue, label),
                      isOpen,
                      onKeyDown: (
                        event: React.KeyboardEvent<HTMLInputElement>
                      ) => {
                        handleInputKeyDown({
                          event,
                          selectHighlightedItem,
                          isOpen
                        })
                        setIsPopoverOpen(true)
                      },
                      onKeyUp: (
                        event: React.KeyboardEvent<HTMLInputElement>
                      ) => {
                        if (event.key === 'Escape') {
                          setIsPopoverOpen(false)
                        }
                      },
                      onChange: (
                        event: React.ChangeEvent<HTMLInputElement>
                      ) => {
                        if (event.target.value === '') {
                          clearSelection()
                          // repopulates the dropdown with location/recent searches after clearing
                          queueMicrotask(() => {
                            openMenu()
                          })
                        }
                      },
                      onClick: (event: React.MouseEvent<HTMLInputElement>) => {
                        event.preventDefault()
                        const target = event.target as HTMLInputElement
                        if (target.value !== '') {
                          target.select()
                          openMenu()
                        }
                        setIsPopoverOpen(true)
                      },
                      onBlur: (event: React.FocusEvent<HTMLInputElement>) => {
                        if (
                          event.relatedTarget?.id === 'dropdown-penny-button'
                        ) {
                          setIsPopoverOpen(true)
                        } else {
                          setIsPopoverOpen(false)
                        }
                      },
                      onFocus: (event: React.FocusEvent<HTMLInputElement>) => {
                        if (
                          showCurrentLocation ||
                          recentOrRecommendationData.length ||
                          event.target.value !== ''
                        ) {
                          openMenu()
                        }
                        setIsPopoverOpen(true)
                      }
                    })}
                  />
                  {errorMessage ? (
                    <WarningIcon color="error" size={20} />
                  ) : null}
                </FormField>
                {errorMessage && typeof errorMessage === 'string' ? (
                  <Tooltip zIndex={2} {...errorProps}>
                    {errorMessage}
                  </Tooltip>
                ) : null}
              </Box>
              <Box id="typeahead-dropdown">
                <Dropdown
                  isTwoLineDisplay={isTwoLineDisplay}
                  shouldDisplayPenny={shouldDisplayPenny}
                  isOpen={isOpen}
                  searchKey={searchKey}
                  searchValue={inputValue}
                  isMobile={isMobile}
                  typeAheadEndpoint={typeAheadEndpoint}
                  highlightedIndex={highlightedIndex}
                  recentSearches={recentOrRecommendationData}
                  recentOrRecommendationTitle={recentOrRecommendationTitle}
                  {...(isMobile ? currentLocationProps : [])}
                  {...restProps}
                />
              </Box>
            </PopoverContainer>
          </Box>
        )}
      </Downshift>
    )
  }
)

export default TypeAhead
