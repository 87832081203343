/* eslint-disable react/destructuring-assignment */
import React from 'react'
import styled from 'styled-components'
import { Box } from 'pcln-design-system'

// Element that creates the shadow effect style if enabled, or render an empty div if not
const ShadowEffectElement = styled(Box)`
  z-index: 10;
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 24, 51, 0.5);
  pointer-events: none;
`

type ShadowEffectProps = {
  enabled?: boolean
  alternativeBlurHandling?: boolean
  children:
    | (({
        isEnabled,
        enableShadowState,
        disableShadowState
      }: {
        isEnabled?: boolean
        enableShadowState?: () => void
        disableShadowState: () => void
      }) => JSX.Element)
    | React.ReactNode
}

type ShadowEffectState = {
  isEnabled: boolean
}

export default class ShadowEffect extends React.PureComponent<
  ShadowEffectProps,
  ShadowEffectState
> {
  disableShadowState: () => void

  enableShadowState: () => void

  handleFocus: () => void

  handleBlur: (e: React.FocusEvent) => void

  node?: HTMLDivElement | null

  constructor(props: ShadowEffectProps) {
    super(props)
    this.state = {
      isEnabled: false
    }

    this.disableShadowState = () => this.setState(() => ({ isEnabled: false }))
    this.enableShadowState = () => this.setState(() => ({ isEnabled: true }))

    this.handleFocus = this.enableShadowState
    this.handleBlur = this.props.alternativeBlurHandling
      ? this.handleAlternativeBlur.bind(this)
      : this.disableShadowState
  }

  componentDidMount() {
    if (this.props.alternativeBlurHandling) {
      // eslint-disable-next-line @typescript-eslint/unbound-method
      document.addEventListener('focusin', this.handleDocumentClick, true)
      // eslint-disable-next-line @typescript-eslint/unbound-method
      document.addEventListener('click', this.handleDocumentClick, true)
    }
  }

  componentWillUnmount() {
    if (this.props.alternativeBlurHandling) {
      // eslint-disable-next-line @typescript-eslint/unbound-method
      document.removeEventListener('focusin', this.handleAlternativeBlur)
      // eslint-disable-next-line @typescript-eslint/unbound-method
      document.removeEventListener('click', this.handleAlternativeBlur)
    }
  }

  handleDocumentClick(e: MouseEvent | FocusEvent) {
    if (
      this.node &&
      e.target instanceof Element &&
      !this.node.contains(e.target)
    ) {
      this.disableShadowState()
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleAlternativeBlur(e: any) {
    const target = e.nativeEvent.relatedTarget
    if (!target || !this.node?.contains(target as Element)) {
      this.setState(() => ({ isEnabled: false }))
    }
  }

  render() {
    const { children, ...remainingProps } = this.props
    const { isEnabled } = this.state
    const { handleBlur, handleFocus, disableShadowState } = this
    return (
      <Box
        width={1}
        onBlur={handleBlur}
        onFocus={handleFocus}
        {...remainingProps}
      >
        {isEnabled && <ShadowEffectElement />}
        <div
          ref={node => {
            this.node = node
          }}
          style={{
            width: '100%',
            position: 'relative',
            zIndex: isEnabled ? 20 : 'auto'
          }}
        >
          {typeof children === 'function'
            ? children({ disableShadowState })
            : children}
        </div>
      </Box>
    )
  }
}
