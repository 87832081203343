import localforage from 'localforage'
import {
  HOTEL_PRODUCT_TYPE,
  RENTAL_CAR_PRODUCT_TYPE,
  VACATION_PACKAGE_PRODUCT_TYPE
} from '@/constants/products'
import { FlightFormStateType } from '@/components/Flights/types'
import { LOCATION_SEARCH_TYPE } from '@/types'

// exported to be reused when setting/getting saved searches
export const AIR_SEARCH_DATA = 'AIR_SEARCH_DATA'
export const CAR_SEARCH_DATA = 'CAR_SEARCH_DATA'
export const HOTEL_SEARCH_DATA = 'HOTEL_SEARCH_DATA'
export const PACKAGE_SEARCH_DATA = 'PACKAGE_SEARCH_DATA'

export type PERSISTED_SEARCH_KEY =
  | typeof AIR_SEARCH_DATA
  | typeof CAR_SEARCH_DATA
  | typeof HOTEL_SEARCH_DATA
  | typeof PACKAGE_SEARCH_DATA

export type SavedSearch = {
  tripType: string
  endLocation: LOCATION_SEARCH_TYPE | null
  startLocation: LOCATION_SEARCH_TYPE | null
  startDate: string
  endDate: string
  timestamp?: number
  flights?: ReadonlyArray<Record<string, unknown>>
  [key: string]: unknown
}

function isSavedSearch(
  item: SavedSearch | null | undefined
): item is SavedSearch {
  return item !== null && item !== undefined
}

function getPersistedSearchData(key: PERSISTED_SEARCH_KEY) {
  return localforage.getItem<SavedSearch[]>(key)
}

export async function getPersistedSearchDataByKeys(
  keys: Array<PERSISTED_SEARCH_KEY>,
  sort = false
) {
  const promiseResultsArray = await Promise.all(
    keys.map(getPersistedSearchData)
  )

  let results: SavedSearch[] = []

  try {
    results = promiseResultsArray.flat().filter(isSavedSearch)
  } catch {
    return []
  }

  // sort is by most recently saved
  if (sort) {
    return results.sort((a: SavedSearch, b: SavedSearch) => {
      if (a.timestamp && b.timestamp) {
        return b.timestamp - a.timestamp
      }
      return 0
    })
  }

  return results
}

const getProductType = (item: SavedSearch) => {
  const { tripType } = item

  switch (tripType) {
    case 'STAY':
      return HOTEL_PRODUCT_TYPE
    case 'AH':
      return RENTAL_CAR_PRODUCT_TYPE
    default:
      return VACATION_PACKAGE_PRODUCT_TYPE
  }
}

const generateRecentSearchDataKey = (item: SavedSearch) => {
  const productType = getProductType(item)
  return `${item.startDate}+${item.endDate}+${
    item.startLocation?.cityID ?? ''
  }+${item.endLocation?.cityID ?? ''}+${productType}`
}

const isDuplicateSearch = (
  recentSearchData: SavedSearch,
  recentSearches: SavedSearch[] | null
) =>
  recentSearches?.some(prevSearchedProduct => {
    const prev = generateRecentSearchDataKey(prevSearchedProduct)
    const current = generateRecentSearchDataKey(recentSearchData)

    return prev === current
  })

export async function persistSearchData(
  key: PERSISTED_SEARCH_KEY,
  recentSearchData: SavedSearch | FlightFormStateType
) {
  const timestamp = Date.now()
  const recentSearch = { ...recentSearchData, timestamp }
  try {
    let groupDifferentProductTypeRecentSearches

    if (key === PACKAGE_SEARCH_DATA || key === HOTEL_SEARCH_DATA) {
      groupDifferentProductTypeRecentSearches =
        await getPersistedSearchDataByKeys(
          [PACKAGE_SEARCH_DATA, HOTEL_SEARCH_DATA],
          true
        )
    }

    const storedSearchesByKey = await getPersistedSearchData(key)

    const storedSearches =
      groupDifferentProductTypeRecentSearches ?? storedSearchesByKey

    if (
      [HOTEL_SEARCH_DATA, PACKAGE_SEARCH_DATA].includes(key) &&
      isDuplicateSearch(recentSearchData as SavedSearch, storedSearches)
    ) {
      return
    }

    return localforage.setItem(
      key,
      [recentSearch, storedSearchesByKey?.[0], storedSearchesByKey?.[1]].filter(
        Boolean
      )
    )
  } catch {
    return localforage.setItem(key, [recentSearch])
  }
}
