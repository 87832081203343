import analytics from '@/shared-utils/analytics'
import config from 'isomorphic-config'

const productMapper = (
  productType: keyof typeof config.client.typeahead.location.endpoint
) => {
  const mappings = {
    hotels: 'hotel',
    cars: 'car',
    packagesOrigin: 'bundle',
    packagesDestination: 'bundle',
    flights: 'air'
  } as const

  return mappings[productType]
}

export default function fireTypeaheadClickEvent(
  typeAheadEndpoint: keyof typeof config.client.typeahead.location.endpoint,
  itemName: string
) {
  analytics.fireGA4Event({
    event: 'select_content',
    attributes: {
      type: 'search_form',
      item_name: itemName,
      product_name: productMapper(typeAheadEndpoint)
    }
  })
}
