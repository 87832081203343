import { NETWORK_ERROR, RESPONSE_OVER_299 } from '@/constants/errorMessages'

export function parseCatchBlockErrors(error: unknown) {
  return error instanceof Error
    ? JSON.stringify(error, Object.getOwnPropertyNames(error))
    : JSON.stringify(error)
}

async function triggerNetworkRequest(
  url: string,
  options: RequestInit | undefined
) {
  try {
    const response = await fetch(url, options)
    return response
  } catch (error) {
    return {
      message: NETWORK_ERROR,
      responseErrorData: {
        responseError: parseCatchBlockErrors(error)
      }
    }
  }
}

function processResponseText(responseText: string) {
  try {
    // Attempt to parse the text as a JSON object
    return JSON.parse(responseText)
  } catch (err) {
    // Parsing response text as JSON failed, this must be a plain-text response.
    return responseText
  }
}

async function parseNetworkData(response: Response) {
  const { status, statusText, type, url: responseUrl } = response
  const responseText = await response.text()

  const data = processResponseText(responseText)

  if (!response.ok) {
    return {
      message: RESPONSE_OVER_299,
      responseErrorData: {
        statusText,
        status,
        responseUrl,
        responseError: JSON.stringify(data),
        type
      }
    }
  }

  return data
}

function isResponseType(
  data:
    | Response
    | { message: string; responseErrorData: Record<string, unknown> }
): data is Response {
  return data instanceof Response
}

export async function fetchAndHandleErrors({
  requestUrl,
  options
}: {
  requestUrl: string
  options?: RequestInit
}) {
  const response = await triggerNetworkRequest(requestUrl, options)

  if (isResponseType(response)) {
    const data = await parseNetworkData(response)

    return data
  }
  return response
}
