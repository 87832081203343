import analytics from './analytics'

type OktaTokenStorage = {
  accessToken: {
    accessToken: string
    tokenType: string
    authorizeUrl: string
    expiresAt: number
  }
}

function getAuthorizationToken() {
  try {
    const oktaStorage = localStorage.getItem('okta-token-storage')
    if (!oktaStorage) {
      return ''
    }

    const oktaStorageParsed: OktaTokenStorage = JSON.parse(oktaStorage)
    if (oktaStorageParsed.accessToken) {
      const { accessToken, tokenType } = oktaStorageParsed.accessToken
      return `${tokenType} ${accessToken}`
    }
    return ''
  } catch (err) {
    analytics.logError({
      message: 'Error reading okta-storage-token',
      error: err
    })

    return ''
  }
}

export default getAuthorizationToken
