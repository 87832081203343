import analytics from '@/shared-utils/analytics'
import { type GA4PageNameType, type GA4ProductType } from '@/ga4types'

export default function fireMaxRoomsEvent(
  pageName: GA4PageNameType,
  productName: GA4ProductType
) {
  analytics.fireGA4Event({
    event: 'internal_element',
    attributes: {
      type: 'search_form',
      page_name: pageName,
      link_name: '10_rooms_or_more',
      product_name: productName
    }
  })
}

export function fireInfantErrorEvent(
  pageName: GA4PageNameType,
  productName: GA4ProductType
) {
  analytics.fireGA4Event({
    event: 'internal_element',
    attributes: {
      type: 'search_form',
      page_name: pageName,
      link_name: 'lab_infant',
      product_name: productName
    }
  })
}
