import { getPlaceData } from '@pcln/build-shop-search-form'
import { LOCATION_SEARCH_TYPE } from '@/types'
import { formatDate } from './date-helper'

type OptionType = {
  isPartialStayDateValid?: boolean
  includeBasicEconomy?: boolean
  hotelStartDate: string
  hotelEndDate: string
  hotelLocation?: LOCATION_SEARCH_TYPE['city']
  roomCount: number
  isCarRequired: boolean
  rcPickupDate?: string
  rcDropoffDate?: string
  rcPickupTime?: string
  rcDropoffTime?: string
  rcLocation?: LOCATION_SEARCH_TYPE
  pickupCarSomewhere?: boolean
  pickupCarIncludeAirport?: boolean
}
const getComponentsFromTripType = (tripType: string, option: OptionType) => {
  let index = 0

  const addProductToComponents = (
    productItem: unknown,
    components: unknown[],
    shouldUnshift?: boolean
  ) => {
    const updatedComponents = [...components]

    if (shouldUnshift) {
      updatedComponents.unshift(productItem)
    } else {
      updatedComponents.push(productItem)
    }
    index += 1
    return updatedComponents
  }

  const addHotelToComponents = (
    components: unknown[],
    optionObj: OptionType
  ) => {
    const productItem = {
      productId: 5,
      index,
      type: 'STAY',
      query: {
        totalNumRooms: optionObj.roomCount,
        ...(optionObj.hotelLocation
          ? { destination: optionObj.hotelLocation }
          : {}),
        ...(optionObj.isPartialStayDateValid
          ? {
              travelStartDate: {
                value: optionObj.hotelStartDate
              },
              travelEndDate: {
                value: optionObj.hotelEndDate
              }
            }
          : {})
      }
    }

    return addProductToComponents(productItem, components, true)
  }

  const addFlightToComponents = (components: unknown[]) => {
    const productItem = {
      productId: 1,
      index,
      type: 'FLY',
      query: {
        includeBasicEconomy: option.includeBasicEconomy
      }
    }

    return addProductToComponents(productItem, components)
  }

  const getRCPlaceData = (carLocation: LOCATION_SEARCH_TYPE) => ({
    ...getPlaceData(carLocation),
    cityName: carLocation.cityName,
    lat: carLocation.lat,
    lon: carLocation.lon,
    stateCode: carLocation.stateCode,
    postalCode: carLocation.postalCode,
    addressLine1: carLocation.addressLine1
  })

  const addCarToComponents = (components: unknown[], optionObj: OptionType) => {
    const productItem = {
      productId: 8,
      type: 'DRIVE',
      index,
      ...(optionObj.isCarRequired ? {} : { isRequired: false })
    }

    if (
      optionObj.pickupCarSomewhere &&
      optionObj.rcPickupDate &&
      optionObj.rcDropoffDate &&
      optionObj.rcLocation
    ) {
      const productItemWithQuery = {
        ...productItem,
        query: {
          pickupDateTime: `${formatDate(
            optionObj.rcPickupDate,
            'yyyy-MM-dd'
          )}T${optionObj.rcPickupTime as string}`,
          returnDateTime: `${formatDate(
            optionObj.rcDropoffDate,
            'yyyy-MM-dd'
          )}T${optionObj.rcDropoffTime as string}`,

          pickupLocation: getRCPlaceData(optionObj.rcLocation),
          returnLocation: getRCPlaceData(optionObj.rcLocation),
          isIncludeAirportLocations: optionObj.pickupCarIncludeAirport
        }
      }

      return addProductToComponents(productItemWithQuery, components)
    }

    return addProductToComponents(productItem, components)
  }

  const mapComponent = (
    components: unknown[],
    type: string,
    optionObject: OptionType
  ) => {
    switch (type) {
      case 'STAY':
        return addHotelToComponents(components, optionObject)
      case 'FLY':
        return addFlightToComponents(components)
      case 'DRIVE':
        return addCarToComponents(components, optionObject)
      default:
        return components
    }
  }

  return tripType
    .split(':')
    .reduce<unknown[]>(
      (components, type) => mapComponent(components, type, option),
      []
    )
}

export default getComponentsFromTripType
