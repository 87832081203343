import { buildShopSearchForm } from '@pcln/build-shop-search-form'
import { LOCATION_SEARCH_TYPE, CityInfo } from '@/types'
import type { ChildrenAges } from '@pcln/traveler-selection'
import { formatDate } from './date-helper'
import getComponentsFromTripType from './get-components-from-triptypes'

export type RecommendedSearchesLocation = CityInfo & {
  id?: string | number
  type?: string
  city?: LOCATION_SEARCH_TYPE['city']
}

type PackageSearchParams = {
  roomCount: number
  adultCount: number
  childrenCount: number
  childrenAges: ChildrenAges | number[]
  startDate: string
  endDate: string
  tripType: string
  endLocationObject: LOCATION_SEARCH_TYPE | RecommendedSearchesLocation | null
  startLocationObject?: LOCATION_SEARCH_TYPE | null
  hotelStartDate?: string
  hotelEndDate?: string
  isPartialStayDateValid?: boolean
  isRecommendedTrip?: boolean
  includeBasicEconomy?: boolean
}

const isLocationFromRecommendedSearches = (
  item: LOCATION_SEARCH_TYPE | RecommendedSearchesLocation
): item is RecommendedSearchesLocation => {
  return !('type' in item && 'id' in item)
}

const packagesSearch = ({
  roomCount = 1,
  adultCount = 2,
  childrenCount = 0,
  childrenAges = {},
  startDate = '',
  endDate = '',
  startLocationObject,
  endLocationObject,
  hotelStartDate = '',
  hotelEndDate = '',
  tripType = 'STAY:FLY',
  isPartialStayDateValid,
  isRecommendedTrip = false,
  includeBasicEconomy = true,
  ...rest
}: Partial<PackageSearchParams>) => {
  const url = '/shop/search/'
  const childrenAgesMock: ChildrenAges | number[] = {}
  if (childrenCount > 0) {
    if (Array.isArray(childrenAges)) {
      childrenAges.forEach((age, i) => {
        const index = i + 1
        const key = `child${index}`
        childrenAgesMock[key] = String(age)
      })
    } else if (typeof childrenAges === 'object') {
      Object.keys(childrenAges).forEach((key, i) => {
        const newKey = `child${i + 1}`
        childrenAgesMock[newKey] = String(childrenAges[key])
      })
    }
  }

  const endLocationObjectCorrected = endLocationObject
    ? { ...endLocationObject }
    : null

  if (endLocationObjectCorrected) {
    // pkg-search uses location.id to create the /relax url
    // for Recommended Searches, this property is undefined.
    if (isLocationFromRecommendedSearches(endLocationObjectCorrected)) {
      endLocationObjectCorrected.id =
        endLocationObjectCorrected.cityID || endLocationObjectCorrected.itemName
      endLocationObjectCorrected.type = 'CITY'
    } else if (
      endLocationObjectCorrected.type === 'POI' &&
      endLocationObjectCorrected.cityID &&
      endLocationObjectCorrected.cityName
    ) {
      endLocationObjectCorrected.type = 'CITY'
    }
  }

  const searchRequest = {
    rooms: roomCount,
    adultCount,
    childCount: childrenCount,
    children: childrenAgesMock,
    departureDate: formatDate(startDate),
    departureLocation: startLocationObject || endLocationObjectCorrected,
    returnDate: formatDate(endDate),
    arrivalLocation: endLocationObjectCorrected,
    pivotComponentKey: 0,
    isRecommendedTrip,
    components: getComponentsFromTripType(tripType, {
      isPartialStayDateValid,
      includeBasicEconomy,
      hotelStartDate: formatDate(hotelStartDate, 'yyyy-MM-dd'),
      hotelEndDate: formatDate(hotelEndDate, 'yyyy-MM-dd'),
      hotelLocation: endLocationObject?.city,
      roomCount,
      isCarRequired: tripType.includes('DRIVE'),
      ...rest
    })
  }

  const form = buildShopSearchForm(url, searchRequest)
  form.submit()
}

export default packagesSearch
