import { Box } from 'pcln-design-system'
import styled from 'styled-components'

const CalendarWrapperBox = styled(Box)`
  [data-autobot-element-id^='DATEPICKER_DAY']:focus {
    outline: 5px auto -webkit-focus-ring-color;
  }
`

export default CalendarWrapperBox
