export const MAX_PASSENGERS = 8
export const MAX_ROOMS = 4
export const MIN_ADULTS = 1
export const MIN_CHILDREN = 0
export const MIN_ROOMS = 1
export const TRAVELER_SELECTION_CONTAINER_MAX_WIDTH = 768

export const labels = {
  ADULTS: 'Adults',
  CHILDREN: 'Children',
  INFANTS: 'Infants on lap',
  ROOMS: 'Rooms'
}

export const subLabels = {
  ADULTS: '(ages 12 and up)',
  CHILDREN: '(ages 2-11)',
  INFANTS: '(under 2)',
  ROOMS: ''
}

export const VP_DISCLAIMER =
  'You may only book a package for up to 8 travelers.'

export const FLIGHTS_DISCLAIMER =
  'You may only book a flight for up to 8 travelers.'
