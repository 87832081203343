import type { GraphResponse, GraphResponseError } from '@/types'

export default function hasGraphErrors<T>(
  payload: GraphResponse
): payload is GraphResponseError<T> {
  if ('errors' in payload && Array.isArray(payload.errors)) {
    return true
  }
  return false
}
